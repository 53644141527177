// src/store/sagas.js

import { takeLatest, fork, put, all, call } from 'redux-saga/effects'
import {
  bannerListApi,
  getBannerDetails,
  addBannerApi,
  updateBanner,
  deleteBannerApi,
} from 'src/helpers/callBanner'

import {
  reqAddBanner,
  reqBannerDetails,
  addBannerSuccess,
  addBannerError,
  bannerDetailsSuccess,
  bannerDetailsError,
  bannerListSuccess,
  bannerListError,
  deleteBannerSuccess,
  deleteBannerError,
  updateBannerSuccess,
  updateBannerError,
} from './action'

import {
  ADD_BANNER_ERROR,
  ADD_BANNER_SUCCESS,
  BANNER_DETAILS_ERROR,
  BANNER_DETAILS_SUCCESS,
  BANNER_LIST_ERROR,
  BANNER_LIST_SUCCESS,
  BANNER_UPDATE_ERROR,
  BANNER_UPDATE_SUCCESS,
  DELETE_BANNER_ERROR,
  DELETE_BANNER_SUCCESS,
  REQ_ADD_BANNER,
  REQ_BANNER_DETAILS,
  REQ_BANNER_LIST,
  REQ_DELETE_BANNER,
  REQ_UPDATE_BANNER,
} from './actionType'

// import api from 'src/api' // Assume you have an API module to handle requests

function* addBannerSaga(action, navigate) {
  try {
    const response = yield call(addBannerApi, action.payload.data)
    yield put(addBannerSuccess(response.data))
    if (navigate) navigate('/getBannerList') // Redirect after adding
  } catch (error) {
    yield put(addBannerError(error.message))
  }
}

function* getBannerDetailsSaga(action) {
  try {
    const response = yield call(getBannerDetails, action.payload)
    yield put(bannerDetailsSuccess(response.data))
  } catch (error) {
    yield put(bannerDetailsError(error.message))
  }
}

function* getBannersSaga() {
  try {
    const response = yield call(bannerListApi)
    yield put(bannerListSuccess(response))
    // yield put({ type: REQ_BANNER_LIST })
  } catch (error) {
    yield put(bannerListError(error.message))
  }
}

function* deleteBannerSaga(action, navigate) {
  try {
    yield call(deleteBannerApi, action.payload)
    yield put(deleteBannerSuccess(action.payload))
    yield put({ type: REQ_BANNER_LIST })
  } catch (error) {
    yield put(deleteBannerError(error.message))
  }
}

function* updateBannerSaga(action, navigate) {
  try {
    const response = yield call(updateBanner, action.payload.id, action.payload.data)
    yield put(updateBannerSuccess(response.data))
    if (navigate) navigate('/getBannerList') // Redirect after updating
  } catch (error) {
    yield put(updateBannerError(error.message))
  }
}

export default function* bannerSaga() {
  yield takeLatest(REQ_ADD_BANNER, addBannerSaga)
  yield takeLatest(REQ_BANNER_DETAILS, getBannerDetailsSaga)
  yield takeLatest(REQ_BANNER_LIST, getBannersSaga)
  yield takeLatest(REQ_DELETE_BANNER, deleteBannerSaga)
  yield takeLatest(REQ_UPDATE_BANNER, updateBannerSaga)
}
