import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiOutlineDelete } from 'react-icons/ai'
import {
  CCardBody,
  CCol,
  CRow,
  CButton,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react'
import Moment from 'moment'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { reqDeleteBanner, reqBannerList } from 'src/store/action'
import Loader from 'src/components/Loader'
const BannerList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [data, setData] = useState([])

  const banners = useSelector((state) => state.Banner?.banners)
  const isBannerListLoading = useSelector((state) => state.Banner?.loading)
  console.log('isBannerListLoading:=========', isBannerListLoading)
  useEffect(() => {
    dispatch(reqBannerList())
  }, [dispatch])

  const deleteBanner = (id) => {
    if (window.confirm(`Are you sure you want to delete this banner permanently?`)) {
      dispatch(reqDeleteBanner(id, navigate))
    }
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>
            <CCol xs={12}>
              <CButton color="secondary" onClick={() => navigate('/addBanner')}>
                Add Banner
              </CButton>
              {isBannerListLoading && <Loader />}
              {!isBannerListLoading && (
                <CCardBody>
                  <CTable striped>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Image</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Update Date</CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-start">
                          Action
                        </CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    {banners?.length > 0 && (
                      <CTableBody>
                        {banners?.map((banner, index) => (
                          <CTableRow key={index}>
                            <CTableDataCell>
                              <img
                                src={banner?.imagePath}
                                style={{
                                  width: '70px',
                                  height: '70px',
                                  objectFit: 'cover',
                                  borderRadius: '5px',
                                }}
                                alt="Banner"
                              />
                            </CTableDataCell>
                            <CTableDataCell>{banner?.description}</CTableDataCell>
                            <CTableDataCell>{banner?.status}</CTableDataCell>
                            <CTableDataCell>
                              {Moment(banner?.updatedAt).format('DD/MM/YYYY')}
                            </CTableDataCell>
                            <CTableDataCell>
                              <CButton
                                class="btn btn-outline-primary btn-loading m-2"
                                onClick={() => navigate(`/updateBanner/${banner._id}`)}
                              >
                                Update
                              </CButton>
                              <CButton
                                type="button"
                                class="btn btn-outline-danger btn-loading m-2"
                                onClick={() => deleteBanner(banner._id)}
                              >
                                <AiOutlineDelete />
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        ))}
                      </CTableBody>
                    )}
                  </CTable>
                </CCardBody>
              )}
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default BannerList
