import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiOutlineDelete } from 'react-icons/ai'
import {
  CCardBody,
  CCol,
  CRow,
  CButton,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react'
import Moment from 'moment'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { reqIntrestDelete, reqIntrestList } from 'src/store/action'
import { SERVER_URL } from 'src/helpers/api_url'

const GetInterestList = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const dispatch = useDispatch()
  const details = useSelector((state) => state.Intrest?.intrestList)

  const isIntrestListLoading = useSelector((state) => state.Intrest?.intrestToggle)
  console.log(details)
  useEffect(() => {
    dispatch(reqIntrestList())
  }, [dispatch])
  const deleteCountry = (id) => {
    if (window.confirm(`Are you sure, you want to delele intrest permenently?`)) {
      dispatch(reqIntrestDelete(id, navigate))
    }
  }
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>
            <CCol xs={12}>
              <CButton color="secondary" onClick={() => navigate('/addInterest')}>
                Add Interest
              </CButton>
              <CCardBody>
                <CTable striped>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">image</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Interest name</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Country</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Update Date</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="text-start">
                        action
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  {details?.length > 0 && (
                    <CTableBody>
                      {details?.map((InterestDetails, index) => (
                        <CTableRow key={index}>
                          <CTableDataCell key={index}>
                            <img
                              src={InterestDetails?.imageUrl}
                              style={{
                                width: '70px',
                                height: '70px',
                                objectFit: 'cover',
                                borderRadius: '5px',
                              }}
                              alt="Interest"
                            ></img>
                          </CTableDataCell>
                          <CTableDataCell>{InterestDetails?.name}</CTableDataCell>
                          <CTableDataCell>{InterestDetails?.countryCode}</CTableDataCell>
                          <CTableDataCell>
                            {Moment(InterestDetails?.updatedAt).format('DD/MM/YYYY')}
                          </CTableDataCell>
                          <CTableDataCell>{InterestDetails?.status}</CTableDataCell>
                          <CTableDataCell>
                            <CButton
                              class="btn btn-outline-primary btn-loading m-2"
                              onClick={() => navigate(`/updateInterest/${InterestDetails._id}`)}
                            >
                              Update
                            </CButton>
                            <CButton
                              class="btn btn-outline-primary btn-loading"
                              onClick={() => navigate(`/getSubInterest/${InterestDetails._id}`)}
                            >
                              Sub Interest
                            </CButton>

                            <CButton type="Button" class="btn btn-outline-primary btn-loading m-2">
                              <AiOutlineDelete onClick={() => deleteCountry(InterestDetails._id)} />
                            </CButton>
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  )}
                </CTable>
              </CCardBody>
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default GetInterestList
