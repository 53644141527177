import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { CCardBody, CCol, CRow, CButton } from '@coreui/react'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { reqIntrestUpdate, reqIntrestList } from 'src/store/action'

const UpdateInterest = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState({})
  const dispatch = useDispatch()
  const details = useSelector((state) => state.Intrest?.intrestList)
  const { register, handleSubmit, reset, watch } = useForm()

  useEffect(() => {
    dispatch(reqIntrestList())
  }, [dispatch])

  useEffect(() => {
    if (details && details.length > 0) {
      let filteredItem = details.find((item) => item._id === id)
      if (filteredItem) {
        filteredItem.status = filteredItem.status === 1 ? 'active' : 'deactive'
        setTimeout(() => setData(filteredItem), 1000)
      }
    }
  }, [details, id])

  useEffect(() => {
    reset(data)
  }, [reset, data])

  const onSubmit = (updatedData) => {
    const finalData = {
      interestId: id,
      name: updatedData.name,
      status: updatedData.status,
      countryCode: updatedData.countryCode,
      imageUrl:
        updatedData.imageUrl && updatedData.imageUrl.length > 0
          ? updatedData.imageUrl[0]
          : updatedData.imageUrl,
    }
    // Create a FormData instance
    const formData = new FormData()

    // Append each property to FormData
    formData.append('interestId', finalData.interestId)
    formData.append('name', finalData.name)
    formData.append('status', finalData.status === 'active' ? 1 : 0)
    formData.append('countryCode', finalData.countryCode)
    // Handle imageUrl conditionally
    if (finalData.imageUrl && updatedData.imageUrl.length > 0) {
      formData.append('imageUrl', finalData.imageUrl)
    } else {
      formData.append('imageUrl', finalData.imageUrl || '')
    }
    dispatch(reqIntrestUpdate(finalData))
  }

  // Watch for status value
  const statusValue = watch('status', data?.status)
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CButton color="secondary" onClick={() => navigate(-1)}>
            Back
          </CButton>
          <CRow>
            <CCol xs={12}>
              <CCardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Interest Name</label>
                    <CCol sm={10}>
                      <input
                        className="form-control m-1 p-2"
                        {...register('name')}
                        type="text"
                        placeholder="Interest Name"
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Interest image</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('imageUrl')}
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <img
                        src={data?.imageUrl || ''}
                        style={{
                          width: '70px',
                          height: '70px',
                          objectFit: 'cover',
                          borderRadius: '5px',
                        }}
                        alt="Interest"
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Country Code</label>
                    <CCol sm={10}>
                      <input
                        className="form-control m-1 p-2"
                        {...register('countryCode')}
                        type="text"
                        placeholder="Country code"
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Status</label>
                    <CCol sm={10}>
                      <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          className="m-2"
                          type="radio"
                          value={'active'}
                          {...register('status', { required: true })}
                          // checked={statusValue == 1}
                        />
                        Active
                      </label>
                      <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          className="m-2"
                          type="radio"
                          value={'deactive'}
                          {...register('status', { required: true })}
                          // checked={statusValue == 0}
                        />
                        Inactive
                      </label>
                    </CCol>
                  </CRow>
                  <CRow className="p-2">
                    <div className="d-grid gap-2">
                      <CButton color="light" size="sm" type="submit">
                        Update
                      </CButton>
                      <CButton color="light" size="sm" onClick={() => navigate(0)}>
                        Cancel
                      </CButton>
                    </div>
                  </CRow>
                </form>
              </CCardBody>
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default UpdateInterest
