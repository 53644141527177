import { takeLatest, fork, put, all, call } from 'redux-saga/effects'
import {
  REQ_ADD_INTREST,
  REQ_DELETE_INTREST,
  REQ_DELETE_SUBINTREST,
  REQ_FOR_SUBINTREST,
  REQ_FOR_SUBINTREST_DETAILS,
  REQ_FOR_UPDATESUBINTREST,
  REQ_INTREST_LIST,
  REQ_UPDATE_INTREST,
  REQ_ADD_SUBINTREST,
} from './actionType'
import {
  DeleteSubintrestError,
  DeleteSubintrestSuccess,
  SubintrestError,
  SubintrestErrorDetails,
  SubintrestSuccess,
  SubintrestSuccessDetails,
  intrestAddError,
  intrestAddSuccess,
  intrestDeleteError,
  intrestDeleteSuccess,
  intrestListError,
  intrestListSuccess,
  intrestUpdateError,
  intrestUpdateSuccess,
  subIntrestUpdateError,
  subIntrestUpdateSuccess,
  subintrestAddError,
  subintrestAddSuccess,
} from './action'
import {
  UpdatIntrestList,
  UpdatSubIntrestList,
  addIntrestApi,
  deleteIntrestApi,
  deleteSubIntrestApi,
  getDetailsSubIntrestApi,
  getSubIntrestApi,
  intrestListApi,
  addSubIntrestApi,
} from 'src/helpers/callIntrest'

function* fetchIntrestList({ payload: req }) {
  try {
    const response = yield call(intrestListApi, req)
    yield put(intrestListSuccess(response))
  } catch (err) {
    yield put(intrestListError(err))
  }
}

function* addIntrest({ payload: req, navigate }) {
  try {
    const response = yield call(addIntrestApi, req)
    yield put(intrestAddSuccess(response))
    if (navigate) navigate('/getInterestList')
  } catch (err) {
    yield put(intrestAddError(err))
  }
}

function* deleteIntrest({ payload: req, navigate }) {
  try {
    const response = yield call(deleteIntrestApi, req)
    yield put(intrestDeleteSuccess(response))
    if (navigate) navigate('/getInterestList')
  } catch (err) {
    yield put(intrestDeleteError(err))
  }
}

function* updateIntrest({ payload: req, navigate }) {
  try {
    console.log(req, 'req')
    const response = yield call(UpdatIntrestList, req)
    yield put(intrestUpdateSuccess(response))
    if (navigate) navigate('/getInterestList')
  } catch (err) {
    yield put(intrestUpdateError(err))
  }
}

function* getSubIntrest({ payload: req }) {
  try {
    console.log(req, 'req')
    const response = yield call(getSubIntrestApi, req)
    yield put(SubintrestSuccess(response))
  } catch (err) {
    yield put(SubintrestError(err))
  }
}

function* deleteSubIntrest({ payload: req, navigate }) {
  try {
    const response = yield call(deleteSubIntrestApi, req)
    yield put(DeleteSubintrestSuccess(response))
    if (navigate) navigate('/getInterestList')
  } catch (err) {
    yield put(DeleteSubintrestError(err))
  }
}

function* updateSubIntrest({ payload: req, navigate }) {
  try {
    console.log(req, 'req')
    const response = yield call(UpdatSubIntrestList, req)
    yield put(subIntrestUpdateSuccess(response))
    if (navigate) navigate('/getInterestList')
  } catch (err) {
    yield put(subIntrestUpdateError(err))
  }
}

function* subIntrestDetails({ payload: req }) {
  try {
    const response = yield call(getDetailsSubIntrestApi, req)
    yield put(SubintrestSuccessDetails(response))
  } catch (err) {
    yield put(SubintrestErrorDetails(err))
  }
}

function* addSubIntrest({ payload: req, navigate }) {
  try {
    const response = yield call(addSubIntrestApi, req)
    yield put(subintrestAddSuccess(response))
    if (navigate) navigate('/getInterestList')
  } catch (err) {
    yield put(subintrestAddError(err))
  }
}

function* watchIntrestlist() {
  yield takeLatest(REQ_INTREST_LIST, fetchIntrestList)
}

function* watchAddIntrest() {
  yield takeLatest(REQ_ADD_INTREST, addIntrest)
}
function* watchAddSubIntrest() {
  yield takeLatest(REQ_ADD_SUBINTREST, addSubIntrest)
}
function* watchDeleteIntrest() {
  yield takeLatest(REQ_DELETE_INTREST, deleteIntrest)
}

function* watchUpdateIntrest() {
  yield takeLatest(REQ_UPDATE_INTREST, updateIntrest)
}

function* watchUpdateSubIntrest() {
  yield takeLatest(REQ_FOR_UPDATESUBINTREST, updateSubIntrest)
}

function* watchSubIntrest() {
  yield takeLatest(REQ_FOR_SUBINTREST, getSubIntrest)
}

function* watchDeleteSubIntrest() {
  yield takeLatest(REQ_DELETE_SUBINTREST, deleteSubIntrest)
}
function* watchDetailsSubIntrest() {
  yield takeLatest(REQ_FOR_SUBINTREST_DETAILS, subIntrestDetails)
}
function* intrestSaga() {
  yield all([
    fork(watchIntrestlist),
    fork(watchAddIntrest),
    fork(watchDeleteIntrest),
    fork(watchUpdateIntrest),
    fork(watchSubIntrest),
    fork(watchDeleteSubIntrest),
    fork(watchDetailsSubIntrest),
    fork(watchUpdateSubIntrest),
    fork(watchAddSubIntrest),
  ])
}

export default intrestSaga
