import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { CCardBody, CCol, CRow, CButton } from '@coreui/react'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { reqUpdateBanner, reqBannerList } from 'src/store/action'

const UpdateBanner = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState({})
  const dispatch = useDispatch()
  const details = useSelector((state) => state.Banner?.banners)
  const { register, handleSubmit, reset, watch } = useForm()
  useEffect(() => {
    dispatch(reqBannerList())
  }, [dispatch])

  useEffect(() => {
    if (details && details.length > 0) {
      const filteredItem = details.find((item) => item._id === id)
      if (filteredItem) {
        setTimeout(() => setData(filteredItem), 1000)
      }
    }
  }, [details, id])

  useEffect(() => {
    reset(data)
  }, [reset, data])
  const onSubmit = (updatedData) => {
    const finalData = {
      bannerId: id,
      description: updatedData.description,
      destinationUrl: updatedData.destinationUrl,
      status: updatedData.status,
      imageFile: updatedData.imageFile?.[0],
    }

    console.log(updatedData, 'finalData:=======', finalData)
    // Create a FormData instance
    const formData = new FormData()

    // Append each property to FormData
    formData.append('bannerId', finalData.bannerId)
    formData.append('description', finalData.description)
    formData.append('status', finalData.status)
    formData.append('destinationUrl', finalData.destinationUrl)
    // // Handle imageUrl conditionally
    if (finalData.imageFile) {
      formData.append('imagePath', finalData.imageFile) // Key should match the server's expectation
    }

    dispatch(reqUpdateBanner(formData, navigate))
  }

  // Watch for status value
  const statusValue = watch('status', data?.status)
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CButton color="secondary" onClick={() => navigate(-1)}>
            Back
          </CButton>
          <CRow>
            <CCol xs={12}>
              <CCardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Banner description *</label>
                    <CCol sm={10}>
                      <input
                        className="form-control m-1 p-2"
                        {...register('description')}
                        type="text"
                        placeholder="Banner Title"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Banner Image *</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('imageFile')}
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        required={data?.imagePath ? false : true}
                      />
                      <img
                        src={data?.imagePath || ''}
                        style={{
                          width: '70px',
                          height: '70px',
                          objectFit: 'cover',
                          borderRadius: '5px',
                        }}
                        alt="Banner"
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Destination URL *</label>
                    <CCol sm={10}>
                      <input
                        className="form-control m-1 p-2"
                        {...register('destinationUrl')}
                        type="text"
                        placeholder="Destination Url"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Status</label>
                    <CCol sm={10}>
                      <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          className="m-2"
                          type="radio"
                          value={1}
                          {...register('status', { required: true })}
                          checked={statusValue == 1}
                        />
                        Active
                      </label>
                      <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          className="m-2"
                          type="radio"
                          value={0}
                          {...register('status', { required: true })}
                          checked={statusValue == 0}
                        />
                        Inactive
                      </label>
                    </CCol>
                  </CRow>
                  <CRow className="p-2">
                    <div className="d-grid gap-2">
                      <CButton color="light" size="sm" type="submit">
                        Update
                      </CButton>
                      <CButton color="light" size="sm" onClick={() => navigate(0)}>
                        Cancel
                      </CButton>
                    </div>
                  </CRow>
                </form>
              </CCardBody>
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default UpdateBanner
