import React, { useState, useEffect } from 'react'
import { getData } from 'country-list'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CInputGroup,
  CLink,
} from '@coreui/react'
import { useDispatch } from 'react-redux'
import { reqAddIntrest } from 'src/store/action'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'

const AddInterest = () => {
  const countryList = getData()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { register, handleSubmit, watch } = useForm()
  const onSubmit = async (data) => {
    const name = data.name
    const countryCode = data.countryCode
    const status = data.status
    const imageUrl = data.imageUrl && data.imageUrl[0]
    const newData = {
      name,
      countryCode,
      status,
      imageUrl,
    }
    // Create a FormData instance
    const formData = new FormData()

    // Append each property to FormData
    formData.append('name', newData.name)
    formData.append('countryCode', newData.countryCode)
    formData.append('status', newData.status)
    // Handle imageUrl conditionally
    if (newData.imageUrl && newData.imageUrl.length > 0) {
      formData.append('imageUrl', newData.imageUrl)
    } else {
      formData.append('imageUrl', newData.imageUrl || '')
    }
    console.log(newData, 'newData')
    dispatch(reqAddIntrest(newData, navigate))
  }
  return (
    <CRow>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CButton color="light" onClick={() => navigate(-1)}>
            Back
          </CButton>
          <br />
          <CCol xs={16}>
            <CCard className="mb-4">
              <CCardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Service name *</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('name', { required: true })}
                        type="text"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Country</label>
                    <CCol sm={10}>
                      {/* <select className="form-control" {...register('countryCode')}>
                        {countries?.map((country) => (
                      <option key={country._id}>{country.countryName}</option>
                    ))}
                      </select> */}
                      <input
                        className="form-control"
                        {...register('countryCode')}
                        type="text"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Interest image *</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('imageUrl')}
                        type="file"
                        accept="image/png image/jpeg image/jpg"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Status</label>
                    <CCol sm={10}>
                      <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          className="mr-2"
                          type="radio"
                          value={1}
                          {...register('status', { required: true })}
                        />
                        Active
                      </label>
                      <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          className="mr-2"
                          type="radio"
                          value={0}
                          {...register('status', { required: true })}
                        />
                        Inactive
                      </label>
                    </CCol>
                  </CRow>

                  <CRow>
                    <div className="d-grid gap-2">
                      <CButton color="light" size="sm" type="submit">
                        Add
                      </CButton>
                      <CButton color="light" size="sm" onClick={() => navigate(0)}>
                        cancel
                      </CButton>
                    </div>
                  </CRow>
                </form>
              </CCardBody>
            </CCard>
          </CCol>
        </div>
        <AppFooter />
      </div>
    </CRow>
  )
}

export default AddInterest
